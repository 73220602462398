// ** React Imports
import { useCallback, useContext } from "react";

// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Custom Components
import Image from "components/layout/Image";

// ** Styled Components
import { StyledPicturesGrid, StyledImageContainer } from "./styles";

// ** Context
import DeviceTypeContext from "contexts/deviceTypeContext";

// #####################################################

const PicturesList = ({ images = [] }) => {
	const { t } = useTranslation(["landing"]);

	const { isPhone } = useContext(DeviceTypeContext);

	// ** Hook useCallback - Funkcja renderująca listę zdjęć
	const renderImagesGrid = useCallback(() => {
		return images?.slice(0, isPhone ? 8 : 12).map((imageItem) => {
			const alt = `${imageItem.login} - ${imageItem.plec}, ${
				imageItem.wiek
			}, ${imageItem.miasto}, ${t(`countries:${imageItem.kraj}`)}`;

			return (
				<li key={alt}>
					<StyledImageContainer>
						<Image
							src={imageItem.media}
							widthRaw="100%"
							realWidth={240}
							realHeight={240}
							alt={alt}
							loading="lazy"
							isBlurred
							fluid
							circle
						/>
					</StyledImageContainer>
				</li>
			);
		});
	}, [images, isPhone]);

	// #####################################################

	return <StyledPicturesGrid>{renderImagesGrid()}</StyledPicturesGrid>;
};

// #####################################################

export default PicturesList;

// #####################################################
