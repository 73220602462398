// ** Third Party Components
import styled from "styled-components";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledPicturesGrid = styled("ul")`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr;
	list-style: none;
	padding: 0;
	flex: 1;
	li {
		padding: 2rem;
	}

	@media (max-width: ${breakpoints.phone - 1}px) {
		margin-top: 0.5rem;
	}

	@media (min-width: ${breakpoints.tablet - 1}px) {
		grid-template-columns: 1fr 1fr 1fr;
		li {
			padding: 1rem;
		}
	}
`;

// #####################################################
